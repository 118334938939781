import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { Routes } from 'infrastructure/routes';

const useStyles = makeStyles<Theme>((style) => ({
  button: {
    color: style.palette.common.white,
    height: 50,
    marginTop: 10,
    
  },
}));

const CookiePolicyMessage: React.FC = () => {
  const [agreed, setAgreed] = useState<boolean>(() => {
    return localStorage.getItem('cookiePolicyAgreed') === 'true';
  });

  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.landingPage);

  const handleAgree = () => {
    setAgreed(true);
    localStorage.setItem('cookiePolicyAgreed', 'true');
  };

  if (agreed) {
    return null;
  }

  return (
    <footer className="footer">
      <p className="footerText">
        {translate('cookiesMessage1')}
        <a
          className="termsAndConditionsText"
          href={Routes.PRIVACY_POLICY}
          style={{ color: '#148287' }}
        >
          {translate('privacyPolicy')}
        </a>
        {translate('cookiesMessage2')}
      </p>
      <Button
        variant="contained"
        color="secondary"
        className={clsx(classes.button, classes.thirdSectionButton)}
        size="medium"
        onClick={handleAgree}
      >
        {translate('agreeToAll')}
      </Button>
    </footer>
  );
};

export default CookiePolicyMessage;